import React from "react"
import {graphql, useStaticQuery, Link} from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query {
            wagtail {
                page(slug: "homepage") {
                    title
                    ... on HomePage {
                        categories {
                            name
                            slug
                            featured
                            image {
                                ...thumbnail
                            }
                        }
                    }
                }
            }
        }
    `)

    const categories = data.wagtail.page.categories;

    return (<Layout>
            <div className="category-list row">
                {categories.map((category) => {
                    return <div className="category col col-xl-3 col-md-6 col-12" key={category.slug}>
                        <Link to={`/category/${category.slug}`}>
                            <div className="category__name">
                                {category.name}
                            </div>
                            {(() => {
                                if (category.image) {
                                    return <Img fluid={category?.image?.thumbnail?.localFile?.childImageSharp?.fluid} />
                                }
                            })()}
                        </Link>
                    </div>
                })}
            </div>
        </Layout>
    )
}

export default IndexPage
